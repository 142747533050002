import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import favicon from '../images/favicon.svg'
import { siteTitle } from '../../site-config';
import Container from './parts/Container'

const LogoWrapper = styled(Container)`
  margin-bottom: 20px;

  h1 {
    display: none;
  }
`

const Logo = styled.div`
  width: 20px;
  height: 20px;

  a {
    border: 0 !important;
    line-height: 0;
    display: block;

    &:hover {
      opacity: .5;
    }
  }
`

const Main = ({ url }) => (
  <LogoWrapper>
    <h1>{ siteTitle }</h1>
    <Logo>
      {
        url
          ? <Link to={url}><img src={favicon} alt="logo" width="20" height="20" /></Link>
          : <img src={favicon} alt="logo" width="20" height="20" />
      }
    </Logo>
  </LogoWrapper>
)

export default Main
