import React from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'
import Layout from '../components/layouts'
import HomeHead from '../components/blog/HomeHead'
import Container from '../components/parts/Container'
import MarkdownRenderer from '../components/parts/Markdown'

const query = graphql`
  query HomepageQuery {
    prismicProfile {
      data {
        description
      }
    }
  }
`

const Homepage = () => (
  <StaticQuery
    query={query}
    render={(data) => (
      <Layout>
        <HomeHead />
        {
          data.prismicProfile.data.description
            ? (
              <Container>
                <MarkdownRenderer markdown={data.prismicProfile.data.description} />
              </Container>
            )
            : null
        }
        <Container>
          <Link to="/posts/1">blog(ja)</Link>
        </Container>
      </Layout>
    )}
  />
)

export default Homepage
